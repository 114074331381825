import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { RecordsPage } from '@bp/shared/models/common';
import { DTO, IEntitiesApiService } from '@bp/shared/models/metadata';
import { NEST_PATH_SEGMENT } from '@bp/shared/models/core';
import { CheckoutUrls } from '@bp/shared/domains/checkouts';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { toHttpParams } from '@bp/frontend/utilities/common';
import { Checkout, CheckoutDTO } from '@bp/frontend/domains/checkout/models';

import { URLS_PATH_SEGMENT } from '@bp/merchant-admin/shared/domains/checkouts';

import {
	CheckoutsQueryParams, CheckoutLimitsSettingsEdit,
	CheckoutActivationRequest, CheckoutActivationResponse
} from '../models';

@Injectable({
	providedIn: 'root',
})
export class CheckoutsApiService implements IEntitiesApiService<Checkout, CheckoutsQueryParams> {

	private readonly __httpClient = inject(HttpClient);

	private readonly __environment = inject(EnvironmentService);

	readonly collectionPath = 'checkouts';

	readonly factory = (dto?: CheckoutDTO): Checkout => new Checkout(dto);

	getRecordsPage(query?: CheckoutsQueryParams): Observable<RecordsPage<Checkout>> {
		return this.__httpClient.get<CheckoutDTO[]>(this.collectionPath, { params: toHttpParams(query) }).pipe(
			map(dtos => dtos.map(this.factory).filter(checkout => !checkout.isVirtualTerminal)),
			map(
				result => new RecordsPage({
					nextPageCursor: null,
					firstPage: null,
					records: result,
				}),
			),
		);
	}

	get(id: string): Observable<Checkout | null> {
		return this.__httpClient
			.get<CheckoutDTO | null>(`${ this.collectionPath }/${ id }`)
			.pipe(map(dto => (dto ? this.factory(dto) : null)));
	}

	save(checkout: Checkout): Observable<Checkout> {
		return (
			checkout.id
				? this.__httpClient.put(`${ this.collectionPath }/${ checkout.id }`, checkout)
				: this.__httpClient.post(`${ this.collectionPath }`, checkout)
		).pipe(map(this.factory));
	}

	delete(checkout: Checkout): Observable<void> {
		return this.__httpClient.delete<void>(`${ this.collectionPath }/${ checkout.id }`);
	}

	saveLimitsSettings(payload: CheckoutLimitsSettingsEdit): Observable<Checkout> {
		return this.__httpClient
			.post<CheckoutDTO>(`${ this.collectionPath }/${ payload.id }/limits`, payload)
			.pipe(map(this.factory));
	}

	saveUrls(id: string, urls: CheckoutUrls): Observable<void> {
		return this.__httpClient
			.post<void>(
				`${ NEST_PATH_SEGMENT }/${ this.collectionPath }/${ id }/${ URLS_PATH_SEGMENT }`,
				urls,
			);
	}

	activate(
		checkout: Checkout,
		activationRequest: CheckoutActivationRequest,
	): Observable<CheckoutActivationResponse> {
		const { signupOrigin, isDevEnvironment, devEnvironmentSlotName } = this.__environment;
		const signupOriginWithSlotName = isDevEnvironment
			? signupOrigin!.replace('signup', `signup-${ devEnvironmentSlotName }`)
			: signupOrigin;

		return this.__httpClient.post<DTO<CheckoutActivationResponse>>(
			`${ signupOriginWithSlotName }/api/v2/license/activate/${ checkout.licenseKey }`,
			activationRequest,
		)
			.pipe(map(response => new CheckoutActivationResponse(response)));
	}

}
